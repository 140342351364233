import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  REPORT_CARD_BATCH, REPORT_CARD_BATCH_API_URL, GET_ALL_REPORT_CARD_BATCH_API_URL,
  GET_GRADE_BATCHS_API_URL, STUDENT_REPORT_CARD_DETAIL_API_URL, GET_SUB_BATCH_SINGLE_CLASS, DOWNLOAD_REPORT_CARD_BATCH,
  PRE_LOAD_DATA,
  HOLD_REPORT_CARD,
  APPROVE_SUB_BATCH,
  APPROVE_BATCH,
  PUBLISH_BATCH,
  ROLL_BACK_APPROVE_SUB_BATCH
} from 'constants/AppConstants';
import { API_BASE_URL } from "constants/ApiConstant";
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  ReportCardBatchErrors: {},
  StudentReportCardDetailErrors: {},
  permission: permission?.report_card_batch ?? [],
  ReportCardBatchShowMessage: null,
  StudentReportCardDetailShowMessage: null,
  ReportCardBatchResult: [],
  StudentAssessmentData: [],
  studentReportCardEdit: false,
  studentAssessmentEdit: false,
  ReportCardBatchButtonSpinner: false,
  filters: {
    gradeLevel: null,
    manage_class_id: null,
    report_card_batch_id: null,
    schoolYear: null,
  },
  class_course_id: null,
  course_id: null,
  FilterPreLoadData: {
    grades: [],
    classes: []
  },
  organization_grade_level_id: null,
  StudentReportCardDetailButtonSpinner: false,
  activeClass: null,
  classApprovalStatus: '',
  activeStudent: null,
  ReportCardBatchTableLoading: true,
  downloadReportCardPDFLoader: false,
  studentReportCardLoading: true,
  studentReportCardBatchLoading: true,
  ReportCardBatchAddDrawer: false,
  ReportCardBatchEditData: [],
  StudentReportCardDetailLoader: false,
  studentReportCard: [],
  ReportCardBatchButtonAndModelLabel: setLocale('reportCardBatch.add'),
  StudentReportCardDetailButtonAndModelLabel: setLocale('reportCardBatch.studentDetailCard'),
  tablePagination: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  sorting: {},
  filter: {},
  ViewReportCardBatchData: [],
  ViewReportCardBatchLoader: true,
  DrawerStatus: 0,
  SubjectId: null,
  StudentReportCardDetailDrawer: false,
  StudentReportCardBatchData: [],
  StudentReportCardData: null,

  assessmentGrades: [],
  holdStudentReportLoader: false,
  approveLoader: false,

  batchFirstClassCourses:[],
  subBatchId: null,
  loadClassCourses: false,
  
};

export const publishBatch = createAsyncThunk("publishBatch", async (data) => {
  try {
    return await CommonService.postData(data, PUBLISH_BATCH);
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors));
  }
});
export const approveBatch = createAsyncThunk("approveBatch", async (data) => {
  try {
    return await CommonService.postData(data, APPROVE_BATCH);
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors));
  }
});
export const approveSubBatch = createAsyncThunk("approveSubBatch", async (data) => {
  try {
    return await CommonService.postData(data, APPROVE_SUB_BATCH);
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors));
  }
});

export const rollBackApproveSubBatch = createAsyncThunk("rollBackApproveSubBatch", async (data) => {
  try {
    return await CommonService.postData(data, ROLL_BACK_APPROVE_SUB_BATCH);
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors));
  }
});

export const holdStudentReport = createAsyncThunk("holdStudentReport", async (data) => {
  try {
    return await CommonService.postData(data, HOLD_REPORT_CARD);
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors));
  }
});
export const createReportCardBatch = createAsyncThunk(
  "createReportCardBatch",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, REPORT_CARD_BATCH_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getReportCardBatch = createAsyncThunk(
  "getReportCardBatch",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_REPORT_CARD_BATCH_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getGradeBatches = createAsyncThunk("getGradeBatches", async (data) => {
  try {
    const response = await CommonService.getAllPost(data, GET_GRADE_BATCHS_API_URL);
    return response;
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
  }
}
);

export const viewReportCardBatch = createAsyncThunk("viewReportCardBatch", async (id) => {
  try {
    return await CommonService.showOne(id, REPORT_CARD_BATCH_API_URL);
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors));
  }
});

export const deleteReportCardBatch = createAsyncThunk(
  "deleteReportCardBatch",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, REPORT_CARD_BATCH_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const saveStudentReportCardDetail = createAsyncThunk(
  "saveStudentReportCardDetail",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, STUDENT_REPORT_CARD_DETAIL_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const subBatchSingleClass = createAsyncThunk(
  "subBatchSingleClass",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_SUB_BATCH_SINGLE_CLASS);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const preLoadData = createAsyncThunk(
  "preLoadData",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, PRE_LOAD_DATA);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const viewReportCard = createAsyncThunk("viewReportCard", async (data) => {
  try {
    return await CommonService.getData(data, DOWNLOAD_REPORT_CARD_BATCH);
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors));
  }
}
);

export const manageReportCardBatchSlice = createSlice({
  name: REPORT_CARD_BATCH,
  initialState,
  reducers: {
    onCloseError: (state, action) => {
      state.ReportCardBatchErrors = {};
      state.StudentReportCardDetailErrors = {};
    },
    ReportCardBatchAddDrawerStatus: (state, action) => {
      if (action.payload.status === false) {
        state.ReportCardBatchButtonSpinner = false;
      }
      state.ReportCardBatchAddDrawer = action.payload.status;
      state.DrawerStatus = action.payload.errorStatus;
      state.ReportCardBatchEditData = [];
      state.ReportCardBatchButtonAndModelLabel = setLocale('reportCardBatch.add');
    },
    ReportCardBatchEditWithDrawerStatus: (state, action) => {
      state.ReportCardBatchAddDrawer = true;
      state.DrawerStatus = action.payload.errorStatus;
      state.ReportCardBatchEditData = action.payload.data;
      state.ReportCardBatchButtonAndModelLabel = setLocale('reportCardBatch.edit');
    },
    updateSortFilters: (state, action) => {
      state.filter = action.payload.filter;
      state.sorting = action.payload.sorting;
    },
    setColumnSearch: (state, action) => {
      state.filter = action.payload;
    },
    setClassCourses: (state, action) => {    
      state.loadClassCourses = true  
      state.studentReportCardLoading = true
      const firstBatchClass = action.payload
      state.batchFirstClassCourses = firstBatchClass?.classCourses ?? []
      state.activeClass = firstBatchClass?.manage_class_id ?? null
      state.subBatchId = firstBatchClass?.id ?? null
      
      const firstBatchClassCourse = firstBatchClass?.classCourses?.find(() => true)
      state.SubjectId = firstBatchClassCourse?.id ?? null
     
      state.loadClassCourses = false               
    },
    loadBatchStudents: (state, action) => {      
      // TODO:: fixing with single class // load from DB
      // state.studentReportCard = action.payload.data?.studentReportCard ?? [];
      // state.activeClass = action.payload?.data ? action.payload?.id : null;
      // state.classApprovalStatus = action.payload?.data ? action.payload?.data?.is_approved_by_home_room_teacher : 0;
      // state.StudentReportCardBatchData = action.payload.data ?? [];
      // state.SubjectId = action.payload.data.classCourses[0]?.id ?? null;
    },
    loadActiveStudents: (state, action) => {
      state.StudentReportCardDetailLoader = true;
      state.activeStudent = action.payload?.data ? action.payload?.id : null;
      state.StudentReportCardData = action.payload.data;
      state.studentAssessmentEdit = false;
      state.studentReportCardEdit = false;
      state.classApprovalStatus = action.payload.data ? action.payload.data?.is_approved_by_home_room_teacher : 0;
    },
    setReportCardDetailDrawerStatus: (state, action) => {
      state.StudentReportCardDetailLoader = action.payload;
    },
    StudentReportCardDetailDrawerStatus: (state, action) => {
      if (action.payload.status === false) {
        state.StudentReportCardDetailButtonSpinner = false;
        state.StudentAssessmentData = action.payload.data;
      }
      state.activeStudent = action.payload.data.id;
      state.DrawerStatus = action.payload.errorStatus;
      state.StudentReportCardDetailDrawer = action.payload.status;
      state.StudentReportCardData = action.payload.data;
    },
    setSubjectId: (state, action) => {
      state.SubjectId = action.payload;
    },
    setESL: (state, action) => {
      // Find the specific report card using the studentReportCardId
      const reportCardIndex = state.studentReportCard?.findIndex(
        (card) => card.id === action.payload.studentReportCardId
      );

      if (reportCardIndex !== -1) {
        const reportCard = state.studentReportCard[reportCardIndex];

        // Find the specific reportCardDetail using SubjectId
        const reportCardDetailIndex = reportCard?.reportCardDetails?.findIndex(
          (detail) => detail.class_course_id === action.payload.SubjectId
        );

        if (reportCardDetailIndex !== -1) {
          // Create a new reportCardDetails array, updating the ESL for the matching SubjectId
          const updatedReportCardDetails = reportCard.reportCardDetails.map(
            (detail, index) =>
              index === reportCardDetailIndex
                ? { ...detail, esl: action.payload.esl ? 1 : 0 } // update ESL for the specific record
                : detail
          );

          // Create a new reportCard with updated details
          const updatedReportCard = {
            ...reportCard,
            reportCardDetails: updatedReportCardDetails,
          };

          // Update the studentReportCard array immutably
          state.studentReportCard = state.studentReportCard.map((card, index) =>
            index === reportCardIndex ? updatedReportCard : card
          );

          // Now update the active class in ViewReportCardBatchData's subBatch
          if (state.ViewReportCardBatchData && state.ViewReportCardBatchData.subBatch) {
            // Update only the subBatch field, leaving the rest of the state unchanged
            state.ViewReportCardBatchData = {
              ...state.ViewReportCardBatchData, // Spread other properties of ViewReportCardBatchData
              subBatch: state.ViewReportCardBatchData.subBatch.map((batch) =>
                batch.id === state.activeClass
                  ? { ...batch, studentReportCard: state.studentReportCard } // Return the updated batch object
                  : batch
              ),
            };
          }
        }
      }

    },
    setIEP: (state, action) => {
      // Find the specific report card using the studentReportCardId
      const reportCardIndex = state.studentReportCard?.findIndex(
        (card) => card.id === action.payload.studentReportCardId
      );

      if (reportCardIndex !== -1) {
        const reportCard = state.studentReportCard[reportCardIndex];

        // Find the specific reportCardDetail using SubjectId
        const reportCardDetailIndex = reportCard?.reportCardDetails?.findIndex(
          (detail) => detail.class_course_id === action.payload.SubjectId
        );

        if (reportCardDetailIndex !== -1) {
          // Create a new reportCardDetails array, updating the ESL for the matching SubjectId
          const updatedReportCardDetails = reportCard.reportCardDetails.map(
            (detail, index) =>
              index === reportCardDetailIndex
                ? { ...detail, iep: action.payload.iep ? 1 : 0 } // update ESL for the specific record
                : detail
          );

          // Create a new reportCard with updated details
          const updatedReportCard = {
            ...reportCard,
            reportCardDetails: updatedReportCardDetails,
          };

          // Update the studentReportCard array immutably
          state.studentReportCard = state.studentReportCard.map((card, index) =>
            index === reportCardIndex ? updatedReportCard : card
          );

          // Now update the active class in ViewReportCardBatchData's subBatch
          if (state.ViewReportCardBatchData && state.ViewReportCardBatchData.subBatch) {
            // Update only the subBatch field, leaving the rest of the state unchanged
            state.ViewReportCardBatchData = {
              ...state.ViewReportCardBatchData, // Spread other properties of ViewReportCardBatchData
              subBatch: state.ViewReportCardBatchData.subBatch.map((batch) =>
                batch.id === state.activeClass
                  ? { ...batch, studentReportCard: state.studentReportCard } // Return the updated batch object
                  : batch
              ),
            };
          }
        }
      }

    },
    setNA: (state, action) => {
      // Find the specific report card using the studentReportCardId
      const reportCardIndex = state.studentReportCard?.findIndex(
        (card) => card.id === action.payload.studentReportCardId
      );

      if (reportCardIndex !== -1) {
        const reportCard = state.studentReportCard[reportCardIndex];

        // Find the specific reportCardDetail using SubjectId
        const reportCardDetailIndex = reportCard?.reportCardDetails?.findIndex(
          (detail) => detail.class_course_id === action.payload.SubjectId
        );

        if (reportCardDetailIndex !== -1) {
          // Create a new reportCardDetails array, updating the ESL for the matching SubjectId
          const updatedReportCardDetails = reportCard.reportCardDetails.map(
            (detail, index) =>
              index === reportCardDetailIndex
                ? { ...detail, na: action.payload.na ? 1 : 0 } // update ESL for the specific record
                : detail
          );

          // Create a new reportCard with updated details
          const updatedReportCard = {
            ...reportCard,
            reportCardDetails: updatedReportCardDetails,
          };

          // Update the studentReportCard array immutably
          state.studentReportCard = state.studentReportCard.map((card, index) =>
            index === reportCardIndex ? updatedReportCard : card
          );

          // Now update the active class in ViewReportCardBatchData's subBatch
          if (state.ViewReportCardBatchData && state.ViewReportCardBatchData.subBatch) {
            // Update only the subBatch field, leaving the rest of the state unchanged
            state.ViewReportCardBatchData = {
              ...state.ViewReportCardBatchData, // Spread other properties of ViewReportCardBatchData
              subBatch: state.ViewReportCardBatchData.subBatch.map((batch) =>
                batch.id === state.activeClass
                  ? { ...batch, studentReportCard: state.studentReportCard } // Return the updated batch object
                  : batch
              ),
            };
          }
        }
      }

    },
    setProgressingVeryWell: (state, action) => {
      // Find the specific report card using the studentReportCardId
      const reportCardIndex = state.studentReportCard?.findIndex(
        (card) => card.id === action.payload.studentReportCardId
      );

      if (reportCardIndex !== -1) {
        const reportCard = state.studentReportCard[reportCardIndex];

        // Find the specific reportCardDetail using SubjectId
        const reportCardDetailIndex = reportCard?.reportCardDetails?.findIndex(
          (detail) => detail.class_course_id === action.payload.SubjectId
        );

        if (reportCardDetailIndex !== -1) {
          // Create a new reportCardDetails array, updating the ESL for the matching SubjectId
          const updatedReportCardDetails = reportCard.reportCardDetails.map(
            (detail, index) =>
              index === reportCardDetailIndex
                ? {
                  ...detail,
                  progressing_very_well: action.payload.progressing_very_well ? 1 : 0,
                  progressing_with_difficulty: 0,
                  progressing_well: 0,
                } // update ESL for the specific record
                : detail
          );

          // Create a new reportCard with updated details
          const updatedReportCard = {
            ...reportCard,
            reportCardDetails: updatedReportCardDetails,
          };

          // Update the studentReportCard array immutably
          state.studentReportCard = state.studentReportCard.map((card, index) =>
            index === reportCardIndex ? updatedReportCard : card
          );

          // Now update the active class in ViewReportCardBatchData's subBatch
          if (state.ViewReportCardBatchData && state.ViewReportCardBatchData.subBatch) {
            // Update only the subBatch field, leaving the rest of the state unchanged
            state.ViewReportCardBatchData = {
              ...state.ViewReportCardBatchData, // Spread other properties of ViewReportCardBatchData
              subBatch: state.ViewReportCardBatchData.subBatch.map((batch) =>
                batch.id === state.activeClass
                  ? { ...batch, studentReportCard: state.studentReportCard } // Return the updated batch object
                  : batch
              ),
            };
          }
        }
      }

    },
    setProgressingWithDifficulty: (state, action) => {
      // Find the specific report card using the studentReportCardId
      const reportCardIndex = state.studentReportCard?.findIndex(
        (card) => card.id === action.payload.studentReportCardId
      );

      if (reportCardIndex !== -1) {
        const reportCard = state.studentReportCard[reportCardIndex];

        // Find the specific reportCardDetail using SubjectId
        const reportCardDetailIndex = reportCard?.reportCardDetails?.findIndex(
          (detail) => detail.class_course_id === action.payload.SubjectId
        );

        if (reportCardDetailIndex !== -1) {
          // Create a new reportCardDetails array, updating the ESL for the matching SubjectId
          const updatedReportCardDetails = reportCard.reportCardDetails.map(
            (detail, index) =>
              index === reportCardDetailIndex
                ? {
                  ...detail,
                  progressing_with_difficulty: action.payload.progressing_with_difficulty ? 1 : 0,
                  progressing_well: 0,
                  progressing_very_well: 0
                } // update ESL for the specific record
                : detail
          );

          // Create a new reportCard with updated details
          const updatedReportCard = {
            ...reportCard,
            reportCardDetails: updatedReportCardDetails,
          };

          // Update the studentReportCard array immutably
          state.studentReportCard = state.studentReportCard.map((card, index) =>
            index === reportCardIndex ? updatedReportCard : card
          );

          // Now update the active class in ViewReportCardBatchData's subBatch
          if (state.ViewReportCardBatchData && state.ViewReportCardBatchData.subBatch) {
            // Update only the subBatch field, leaving the rest of the state unchanged
            state.ViewReportCardBatchData = {
              ...state.ViewReportCardBatchData, // Spread other properties of ViewReportCardBatchData
              subBatch: state.ViewReportCardBatchData.subBatch.map((batch) =>
                batch.id === state.activeClass
                  ? { ...batch, studentReportCard: state.studentReportCard } // Return the updated batch object
                  : batch
              ),
            };
          }
        }
      }

    },
    setProgressingWell: (state, action) => {
      // Find the specific report card using the studentReportCardId
      const reportCardIndex = state.studentReportCard?.findIndex(
        (card) => card.id === action.payload.studentReportCardId
      );

      if (reportCardIndex !== -1) {
        const reportCard = state.studentReportCard[reportCardIndex];

        // Find the specific reportCardDetail using SubjectId
        const reportCardDetailIndex = reportCard?.reportCardDetails?.findIndex(
          (detail) => detail.class_course_id === action.payload.SubjectId
        );

        if (reportCardDetailIndex !== -1) {
          // Create a new reportCardDetails array, updating the ESL for the matching SubjectId
          const updatedReportCardDetails = reportCard.reportCardDetails.map(
            (detail, index) =>
              index === reportCardDetailIndex
                ? {
                  ...detail,
                  progressing_well: action.payload.progressing_well ? 1 : 0,
                  progressing_with_difficulty: 0,
                  progressing_very_well: 0
                } // update ESL for the specific record
                : detail
          );

          // Create a new reportCard with updated details
          const updatedReportCard = {
            ...reportCard,
            reportCardDetails: updatedReportCardDetails,
          };

          // Update the studentReportCard array immutably
          state.studentReportCard = state.studentReportCard.map((card, index) =>
            index === reportCardIndex ? updatedReportCard : card
          );

          // Now update the active class in ViewReportCardBatchData's subBatch
          if (state.ViewReportCardBatchData && state.ViewReportCardBatchData.subBatch) {
            // Update only the subBatch field, leaving the rest of the state unchanged
            state.ViewReportCardBatchData = {
              ...state.ViewReportCardBatchData, // Spread other properties of ViewReportCardBatchData
              subBatch: state.ViewReportCardBatchData.subBatch.map((batch) =>
                batch.id === state.activeClass
                  ? { ...batch, studentReportCard: state.studentReportCard } // Return the updated batch object
                  : batch
              ),
            };
          }
        }
      }

    },
    setComments: (state, action) => {
      const reportCardIndex = state.studentReportCard?.findIndex(
        (card) => card.id === action.payload.studentReportCardId
      );

      if (reportCardIndex !== -1) {
        const reportCard = state.studentReportCard[reportCardIndex];

        // Find the specific reportCardDetail using SubjectId
        const reportCardDetailIndex = reportCard?.reportCardDetails?.findIndex(
          (detail) => detail.class_course_id === action.payload.SubjectId
        );

        if (reportCardDetailIndex !== -1) {
          // Create a new reportCardDetails array, updating the ESL for the matching SubjectId
          const updatedReportCardDetails = reportCard.reportCardDetails.map(
            (detail, index) =>
              index === reportCardDetailIndex
                ? { ...detail, comments1: action.payload.comments1 ?? '' } // update ESL for the specific record
                : detail
          );

          // Create a new reportCard with updated details
          const updatedReportCard = {
            ...reportCard,
            reportCardDetails: updatedReportCardDetails,
          };

          // Update the studentReportCard array immutably
          state.studentReportCard = state.studentReportCard.map((card, index) =>
            index === reportCardIndex ? updatedReportCard : card
          );

          // Now update the active class in ViewReportCardBatchData's subBatch
          if (state.ViewReportCardBatchData && state.ViewReportCardBatchData.subBatch) {
            // Update only the subBatch field, leaving the rest of the state unchanged
            state.ViewReportCardBatchData = {
              ...state.ViewReportCardBatchData, // Spread other properties of ViewReportCardBatchData
              subBatch: state.ViewReportCardBatchData.subBatch.map((batch) =>
                batch.id === state.activeClass
                  ? { ...batch, studentReportCard: state.studentReportCard } // Return the updated batch object
                  : batch
              ),
            };
          }
        }
      }

    },
    setCourseCategoryComment: (state, action) => {
      const reportCardIndex = state.studentReportCard?.findIndex(
        (card) => card.id === action.payload.studentReportCardId
      );

      if (reportCardIndex !== -1) {
        const reportCard = state.studentReportCard[reportCardIndex];

        // Traverse through reportCardDetails to find and update the correct course
        const updatedReportCardDetails = reportCard.reportCardDetails.map((detail) => {
          const updatedCourses = detail.courses.map((course) => {
            if (course.class_course_id === action.payload.SubjectId) {
              return { ...course, comments1: action.payload.comments1 ?? '' }; // Update mark1
            }
            return course;
          });

          return { ...detail, courses: updatedCourses };
        });

        // Create a new reportCard object with updated reportCardDetails
        const updatedReportCard = {
          ...reportCard,
          reportCardDetails: updatedReportCardDetails,
        };

        // Update the studentReportCard array immutably
        state.studentReportCard = state.studentReportCard.map((card, index) =>
          index === reportCardIndex ? updatedReportCard : card
        );

        // Update the active class in ViewReportCardBatchData's subBatch
        if (state.ViewReportCardBatchData && state.ViewReportCardBatchData.subBatch) {
          state.ViewReportCardBatchData = {
            ...state.ViewReportCardBatchData,
            subBatch: state.ViewReportCardBatchData.subBatch.map((batch) =>
              batch.id === state.activeClass
                ? { ...batch, studentReportCard: state.studentReportCard }
                : batch
            ),
          };
        }
      }

    },
    setMark1: (state, action) => {
      const reportCardIndex = state.studentReportCard?.findIndex(
        (card) => card.id === action.payload.studentReportCardId
      );

      // console.log(action.payload, "payload");

      if (reportCardIndex !== -1) {
        const reportCard = state.studentReportCard[reportCardIndex];

        // Traverse through reportCardDetails to find and update the correct course
        const updatedReportCardDetails = reportCard.reportCardDetails.map((detail) => {
          const updatedCourses = detail.courses.map((course) => {
            if (course.class_course_id === action.payload.SubjectId) {
              return { ...course, mark1: action.payload.mark1 ?? '' }; // Update mark1
            }
            return course;
          });

          return { ...detail, courses: updatedCourses };
        });

        // Create a new reportCard object with updated reportCardDetails
        const updatedReportCard = {
          ...reportCard,
          reportCardDetails: updatedReportCardDetails,
        };

        // Update the studentReportCard array immutably
        state.studentReportCard = state.studentReportCard.map((card, index) =>
          index === reportCardIndex ? updatedReportCard : card
        );

        // Update the active class in ViewReportCardBatchData's subBatch
        if (state.ViewReportCardBatchData && state.ViewReportCardBatchData.subBatch) {
          state.ViewReportCardBatchData = {
            ...state.ViewReportCardBatchData,
            subBatch: state.ViewReportCardBatchData.subBatch.map((batch) =>
              batch.id === state.activeClass
                ? { ...batch, studentReportCard: state.studentReportCard }
                : batch
            ),
          };
        }
      }
    },

    setMark2: (state, action) => {
      const reportCardIndex = state.studentReportCard?.findIndex(
        (card) => card.id === action.payload.studentReportCardId
      );

      if (reportCardIndex !== -1) {
        const reportCard = state.studentReportCard[reportCardIndex];

        // Find the specific reportCardDetail using SubjectId
        const reportCardDetailIndex = reportCard?.reportCardDetails?.findIndex(
          (detail) => detail.class_course_id === action.payload.SubjectId
        );

        if (reportCardDetailIndex !== -1) {
          // Create a new reportCardDetails array, updating the ESL for the matching SubjectId
          const updatedReportCardDetails = reportCard.reportCardDetails.map(
            (detail, index) =>
              index === reportCardDetailIndex
                ? { ...detail, mark2: action.payload.mark2 ?? '' } // update ESL for the specific record
                : detail
          );

          // Create a new reportCard with updated details
          const updatedReportCard = {
            ...reportCard,
            reportCardDetails: updatedReportCardDetails,
          };

          // Update the studentReportCard array immutably
          state.studentReportCard = state.studentReportCard.map((card, index) =>
            index === reportCardIndex ? updatedReportCard : card
          );

          // Now update the active class in ViewReportCardBatchData's subBatch
          if (state.ViewReportCardBatchData && state.ViewReportCardBatchData.subBatch) {
            // Update only the subBatch field, leaving the rest of the state unchanged
            state.ViewReportCardBatchData = {
              ...state.ViewReportCardBatchData, // Spread other properties of ViewReportCardBatchData
              subBatch: state.ViewReportCardBatchData.subBatch.map((batch) =>
                batch.id === state.activeClass
                  ? { ...batch, studentReportCard: state.studentReportCard } // Return the updated batch object
                  : batch
              ),
            };
          }
        }
      }

    },
    setStudentAssessment: (state, action) => {
      state.StudentAssessmentData = action.payload
    },

    updateStudentAssessmentGrade: (state, action) => {
      // Find the specific report card using the studentReportCardId
      const reportCardIndex = state.studentReportCard?.findIndex(
        (card) => card.id === action.payload.studentReportCardId
      );

      if (reportCardIndex !== -1) {
        const reportCard = state.studentReportCard[reportCardIndex];

        // Find the specific reportCardDetail using SubjectId
        const studentAssessmentIndex = reportCard?.studentAssessment?.findIndex(
          (detail) => detail.id === action.payload.assessmentId
        );

        if (studentAssessmentIndex !== -1) {
          // Create a new assessment array, updating the ESL for the matching SubjectId
          const updatedAssessment = reportCard.studentAssessment.map(
            (detail, index) =>
              index === studentAssessmentIndex
                ? { ...detail, assessment_grade_id: action.payload.assessment_grade_id ?? null } // update ESL for the specific record
                : detail
          );

          // Create a new reportCard with updated details
          const updatedReportCard = {
            ...reportCard,
            studentAssessment: updatedAssessment,
          };

          // Update the studentReportCard array immutably
          state.studentReportCard = state.studentReportCard.map((card, index) =>
            index === reportCardIndex ? updatedReportCard : card
          );
          state.StudentReportCardData = updatedReportCard;

          state.activeStudent = action.payload.studentReportCardId;

          // Now update the active class in ViewReportCardBatchData's subBatch
          if (state.ViewReportCardBatchData && state.ViewReportCardBatchData.subBatch) {
            // Update only the subBatch field, leaving the rest of the state unchanged
            state.ViewReportCardBatchData = {
              ...state.ViewReportCardBatchData, // Spread other properties of ViewReportCardBatchData
              subBatch: state.ViewReportCardBatchData.subBatch.map((batch) =>
                batch.id === state.activeClass
                  ? { ...batch, studentReportCard: state.studentReportCard } // Return the updated batch object
                  : batch
              ),
            };
          }


        }
      }

    },
    updateStudentAssessmentComment: (state, action) => {
      // Find the specific report card using the studentReportCardId
      const reportCardIndex = state.studentReportCard?.findIndex(
        (card) => card.id === action.payload.studentReportCardId
      );

      if (reportCardIndex !== -1) {
        const reportCard = state.studentReportCard[reportCardIndex];

        // Find the specific reportCardDetail using SubjectId
        const studentAssessmentIndex = reportCard?.studentAssessment?.findIndex(
          (detail) => detail.id === action.payload.assessmentId
        );

        if (studentAssessmentIndex !== -1) {
          // Create a new reportCardDetails array, updating the ESL for the matching SubjectId
          const updatedAssessment = reportCard.studentAssessment.map(
            (detail, index) =>
              index === studentAssessmentIndex
                ? { ...detail, comments: action.payload.comments ?? null } // update ESL for the specific record
                : detail
          );

          // Create a new reportCard with updated details
          const updatedReportCard = {
            ...reportCard,
            studentAssessment: updatedAssessment,
          };

          // Update the studentReportCard array immutably
          state.studentReportCard = state.studentReportCard.map((card, index) =>
            index === reportCardIndex ? updatedReportCard : card
          );

          state.StudentReportCardData = updatedReportCard;

          // Now update the active class in ViewReportCardBatchData's subBatch
          if (state.ViewReportCardBatchData && state.ViewReportCardBatchData.subBatch) {
            // Update only the subBatch field, leaving the rest of the state unchanged
            state.ViewReportCardBatchData = {
              ...state.ViewReportCardBatchData, // Spread other properties of ViewReportCardBatchData
              subBatch: state.ViewReportCardBatchData.subBatch.map((batch) =>
                batch.id === state.activeClass
                  ? { ...batch, studentReportCard: state.studentReportCard } // Return the updated batch object
                  : batch
              ),
            };
          }
        }
      }

    },
    setOrganizationGradeLevelId: (state, action) => {
      state.organization_grade_level_id = action.payload
    },
    setClassCourseId: (state, action) => {
      state.class_course_id = action.payload
    },
    setCourseId: (state, action) => {
      state.course_id = action.payload
    },
    setFilterData: (state, action) => {
      state.filters = action.payload
    },
    setStudentReportCardEdit: (state, action) => {
      state.studentReportCardEdit = action.payload
    },
    setStudentAssessmentEdit: (state, action) => {
      state.studentAssessmentEdit = action.payload
    },
    setReportCardData: (state, action) => {
      // Find the specific report card using the studentReportCardId
      const reportCardIndex = state.StudentReportCardData.reportCardDetails?.findIndex(
        (card) => card.id === action.payload.studentReportCardId
      );

      if (reportCardIndex !== -1) {
        // Get the report card by index
        const reportCard = state.StudentReportCardData.reportCardDetails[reportCardIndex];

        let updatedReportCard = {};
        // Update ESL for the specific report card
        if (action.payload.field === 'esl') {
          updatedReportCard = {
            ...reportCard,
            esl: action.payload.checkStatus ? 1 : 0, // Update the ESL field based on the action payload
          };
        } else if (action.payload.field === 'iep') {
          updatedReportCard = {
            ...reportCard,
            iep: action.payload.checkStatus ? 1 : 0, // Update the ESL field based on the action payload
          };
        } else if (action.payload.field === 'na') {
          updatedReportCard = {
            ...reportCard,
            na: action.payload.checkStatus ? 1 : 0, // Update the ESL field based on the action payload
          };
        } else if (action.payload.field === 'progressing_with_difficulty') {
          updatedReportCard = {
            ...reportCard,
            progressing_with_difficulty: action.payload.checkStatus ? 1 : 0, // Update the ESL field based on the action payload
            progressing_well: 0,
            progressing_very_well: 0,
          };
        } else if (action.payload.field === 'progressing_well') {
          updatedReportCard = {
            ...reportCard,
            progressing_well: action.payload.checkStatus ? 1 : 0, // Update the ESL field based on the action payload
            progressing_with_difficulty: 0,
            progressing_very_well: 0
          };
        } else if (action.payload.field === 'progressing_very_well') {
          updatedReportCard = {
            ...reportCard,
            progressing_very_well: action.payload.checkStatus ? 1 : 0, // Update the ESL field based on the action payload
            progressing_with_difficulty: 0,
            progressing_well: 0
          };
        } else if (action.payload.field === 'mark1') {
          updatedReportCard = {
            ...reportCard,
            mark1: action.payload.text ?? '', // Update the mark1 field based on the action payload
          };
        } else if (action.payload.field === 'mark2') {
          updatedReportCard = {
            ...reportCard,
            mark2: action.payload.text ?? '', // Update the mark2 field based on the action payload
          };
        } else if (action.payload.field === 'comments1') {
          updatedReportCard = {
            ...reportCard,
            comments1: action.payload.text ?? '', // Update the ESL field based on the action payload
          };
        }

        // Update the entire StudentReportCardData with the modified report card
        state.StudentReportCardData.reportCardDetails = state.StudentReportCardData.reportCardDetails.map(
          (card, index) => (index === reportCardIndex ? updatedReportCard : card)
        );
      }
    },
    setHoldStudentReportCard: (state, action) => {
      const studentReportCardIndex = state.studentReportCard.findIndex(
        (card) => card.id === action.payload.id
      );

      if (studentReportCardIndex !== -1) {
        state.studentReportCard[studentReportCardIndex].is_withheld = action.payload.is_withheld ? 1 : 0;
      }
    },
    setProvisionalReportCardData: (state, action) => {
      const { selectedCategory, selectedCourseId, selectedStrandId, newValue } = action.payload;
      state.StudentReportCardData.reportCardDetails = state.StudentReportCardData.reportCardDetails.map((category) => {
        if (category.category !== selectedCategory) return category;
        return {
          ...category,
          courses: category.courses.map((course) => {
            if (course.class_course_id !== selectedCourseId) return course;
            return {
              ...course,
              strands: course.strands.map((strand) =>
                strand.id === selectedStrandId ? { ...strand, value: newValue } : strand
              )
            };
          })
        };
      });
    },
    setProvisionalReportCardCommentData: (state, action) => {
      const { selectedCategory, selectedCourseId, newValue, column } = action.payload;
      state.StudentReportCardData.reportCardDetails = state.StudentReportCardData.reportCardDetails.map((category) => {
        if (category.category !== selectedCategory) return category;
        return {
          ...category,
          courses: category.courses.map((course) => {
            if (course.class_course_id !== selectedCourseId) return course;
            return {
              ...course,
              [column]: newValue
            };
          })
        };
      });
    },

    setCheckboxValue: (state, action) => {
      // Find the specific report card using the studentReportCardId
      const reportCardIndex = state.studentReportCard?.findIndex(
        (card) => card.id === action.payload.studentReportCardId
      );

      if (reportCardIndex !== -1) {
        const reportCard = state.studentReportCard[reportCardIndex];

        // Update the reportCardDetails
        const updatedReportCardDetails = reportCard.reportCardDetails.map((detail) => {
          return {
            ...detail,
            courses: detail.courses.map((course) => {
              if (course.class_course_id === action.payload.SubjectId) {
                // Update the specific strand within the course
                return {
                  ...course,
                  strands: course.strands.map((strand) =>
                    strand.id === action.payload.strandId
                      ? { ...strand, value: action.payload.value ? 1 : 0 } // Update the strand value
                      : strand
                  ),
                };
              }
              return course; // Keep other courses unchanged
            }),
          };
        });

        // Create a new reportCard with updated details
        const updatedReportCard = {
          ...reportCard,
          reportCardDetails: updatedReportCardDetails,
        };

        // Update the studentReportCard array immutably
        state.studentReportCard = state.studentReportCard.map((card, index) =>
          index === reportCardIndex ? updatedReportCard : card
        );

        // Update the batch data if necessary
        if (state.ViewReportCardBatchData && state.ViewReportCardBatchData.subBatch) {
          state.ViewReportCardBatchData = {
            ...state.ViewReportCardBatchData,
            subBatch: state.ViewReportCardBatchData.subBatch.map((batch) =>
              batch.id === state.activeClass
                ? { ...batch, studentReportCard: state.studentReportCard }
                : batch
            ),
          };
        }
      }
    },


  },
  extraReducers: (builder) => {
    builder      
      .addCase(publishBatch.pending, (state, action) => {
        state.approveLoader = true
      }).addCase(publishBatch.fulfilled, (state, action) => {
        state.approveLoader = false
      }).addCase(publishBatch.rejected, (state, action) => {
        state.approveLoader = false
      })
      .addCase(approveBatch.pending, (state, action) => {
        state.approveLoader = true
      }).addCase(approveBatch.fulfilled, (state, action) => {
        state.approveLoader = false
      }).addCase(approveBatch.rejected, (state, action) => {
        state.approveLoader = false
      })
      .addCase(approveSubBatch.pending, (state, action) => {
        state.approveLoader = true
      }).addCase(approveSubBatch.fulfilled, (state, action) => {
        state.approveLoader = false
        // state.StudentReportCardData = null
      }).addCase(approveSubBatch.rejected, (state, action) => {
        state.approveLoader = false
      })
      .addCase(rollBackApproveSubBatch.pending, (state, action) => {
        state.approveLoader = true
      }).addCase(rollBackApproveSubBatch.fulfilled, (state, action) => {
        state.approveLoader = false
        // state.StudentReportCardData = null
      }).addCase(rollBackApproveSubBatch.rejected, (state, action) => {
        state.approveLoader = false
      })
      .addCase(holdStudentReport.pending, (state, action) => {
        state.holdStudentReportLoader = true
      }).addCase(holdStudentReport.fulfilled, (state, action) => {
        state.holdStudentReportLoader = false
      }).addCase(holdStudentReport.rejected, (state, action) => {
        state.holdStudentReportLoader = false
      })
      .addCase(createReportCardBatch.pending, (state, action) => {
        state.DrawerStatus = 0
        state.ReportCardBatchButtonSpinner = true;
      }).addCase(createReportCardBatch.fulfilled, (state, action) => {
        state.DrawerStatus = 0
        state.ReportCardBatchButtonSpinner = false;
        state.ReportCardBatchErrors = {}
      }).addCase(createReportCardBatch.rejected, (state, action) => {
        state.DrawerStatus = 1
        state.ReportCardBatchShowMessage = true;
        state.ReportCardBatchButtonSpinner = false;
        state.ReportCardBatchErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })

      .addCase(saveStudentReportCardDetail.pending, (state, action) => {
        state.DrawerStatus = 0
        state.StudentReportCardDetailButtonSpinner = true;
      }).addCase(saveStudentReportCardDetail.fulfilled, (state, action) => {
        state.DrawerStatus = 0
        state.StudentReportCardDetailButtonSpinner = false;
        state.StudentReportCardDetailErrors = {}
      }).addCase(saveStudentReportCardDetail.rejected, (state, action) => {
        state.DrawerStatus = 1
        state.StudentReportCardDetailShowMessage = true;
        state.StudentReportCardDetailButtonSpinner = false;
        state.StudentReportCardDetailErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })

      .addCase(getReportCardBatch.pending, (state, action) => {
        state.ReportCardBatchButtonSpinner = true;
        state.ReportCardBatchTableLoading = true;
      }).addCase(getReportCardBatch.fulfilled, (state, action) => {
        state.ReportCardBatchButtonSpinner = false;
        state.ReportCardBatchTableLoading = false;
        state.ReportCardBatchResult = action.payload;
        state.tablePagination = {
          ...state.tablePagination,
          total: action.payload.pagination.total,
          current: action.payload.pagination.current_page,
          pageSize: action.payload.pagination.per_page
        };
      }).addCase(getReportCardBatch.rejected, (state, action) => {
        state.ReportCardBatchShowMessage = true; // Set the showMessage flag to display the errors
        state.ReportCardBatchButtonSpinner = false;
        state.ReportCardBatchTableLoading = false;
        state.ReportCardBatchErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })

      .addCase(getGradeBatches.pending, (state, action) => {

      }).addCase(getGradeBatches.fulfilled, (state, action) => {
        state.FilterPreLoadData.classes = action.payload;
      }).addCase(getGradeBatches.rejected, (state, action) => {
      })


      .addCase(subBatchSingleClass.pending, (state, action) => {
        state.studentReportCardLoading = true;
        state.StudentReportCardData = null;
        state.studentReportCard = [];
      }).addCase(subBatchSingleClass.fulfilled, (state, action) => {
        state.studentReportCardLoading = false;
        state.studentReportCard = action.payload;
      }).addCase(subBatchSingleClass.rejected, (state, action) => {
        state.studentReportCardLoading = false;
      })

      .addCase(preLoadData.pending, (state, action) => {
        state.ViewReportCardBatchLoader = true;
      }).addCase(preLoadData.fulfilled, (state, action) => {
        state.ViewReportCardBatchLoader = false;
        state.assessmentGrades = action.payload?.assessmentGrades;
        state.FilterPreLoadData.classes = action.payload?.FilterPreLoadData.classes;
        state.FilterPreLoadData.grades = action.payload?.FilterPreLoadData.grades;
      }).addCase(preLoadData.rejected, (state, action) => {
        state.ViewReportCardBatchLoader = false;
      })



      .addCase(viewReportCardBatch.pending, (state, action) => {
        state.studentReportCardLoading = true;
        state.ViewReportCardBatchLoader = true;
        state.ViewReportCardBatchData = [];
        state.batchFirstClassCourses = [];
      }).addCase(viewReportCardBatch.fulfilled, (state, action) => {
        state.ViewReportCardBatchLoader = false;
        state.studentReportCardLoading = false;
        state.ViewReportCardBatchData = action.payload;

        const firstBatchClass = action.payload?.batchClasses?.find(() => true)
        state.batchFirstClassCourses = firstBatchClass?.classCourses ?? []
        state.activeClass = firstBatchClass?.manage_class_id ?? null
        state.subBatchId = firstBatchClass?.id ?? null
        state.classApprovalStatus = firstBatchClass?.is_approved_by_home_room_teacher ?? 0

        const firstBatchClassCourse = firstBatchClass?.classCourses?.find(() => true)
        state.SubjectId = firstBatchClassCourse?.id ?? null

        // state.assessmentGrades = action.payload?.assessmentGrades;
        // state.FilterPreLoadData.classes = action.payload?.FilterPreLoadData.classes;
        // state.FilterPreLoadData.grades = action.payload?.FilterPreLoadData.grades;


        // let batchKey;
        // let courseKey;
        // if (action.payload?.subBatch && action.payload?.subBatch.length > 0) {
        //   const obj = action.payload?.subBatch;
        //   for (const key in obj) {
        //     batchKey = key;
        //     const course = action.payload?.subBatch[batchKey]?.classCourses;
        //     for (const cKey in course) {
        //       courseKey = cKey;
        //       break;
        //     }
        //     break;
        //   }
        // }
        // state.StudentReportCardData = null;
        // state.studentReportCard = action.payload?.subBatch && action.payload?.subBatch.length > 0 ? action.payload?.subBatch[batchKey ?? 0]?.studentReportCard : [];
        // state.activeClass = action.payload?.subBatch && action.payload?.subBatch.length > 0 ? action.payload?.subBatch[batchKey ?? 0]?.id : null;
        // state.classApprovalStatus = action.payload?.subBatch && action.payload?.subBatch.length > 0 ? action.payload?.subBatch[batchKey ?? 0]?.is_approved_by_home_room_teacher : 0;
        // state.StudentReportCardBatchData = action.payload?.subBatch && action.payload?.subBatch.length > 0 ? action.payload?.subBatch[batchKey ?? 0] : [];
        // state.SubjectId = action.payload?.subBatch[batchKey ?? 0]?.classCourses[courseKey ?? 0]?.id ?? null;
      }).addCase(viewReportCardBatch.rejected, (state, action) => {
        state.studentReportCardLoading = false;
        state.ViewReportCardBatchLoader = false;
        state.ViewReportCardBatchData = [];
      })


      .addCase(deleteReportCardBatch.pending, (state, action) => {
        state.ReportCardBatchTableLoading = true;
      }).addCase(deleteReportCardBatch.fulfilled, (state, action) => {
        state.ReportCardBatchTableLoading = false;
      }).addCase(deleteReportCardBatch.rejected, (state, action) => {
        state.ReportCardBatchTableLoading = false;
      })

      .addCase(viewReportCard.pending, (state, action) => {
        state.downloadReportCardPDFLoader = true;
      }).addCase(viewReportCard.fulfilled, (state, action) => {
        state.downloadReportCardPDFLoader = false;
        window.open(action.payload, '_blank');

      }).addCase(viewReportCard.rejected, (state, action) => {
        state.downloadReportCardPDFLoader = false;
      });
  },
});

export const {
  onCloseError,
  setColumnSearch,
  ReportCardBatchAddDrawerStatus,
  ReportCardBatchEditWithDrawerStatus,
  loadBatchStudents,
  setClassCourses,
  updateSortFilters,
  setFilterData,
  setESL,
  setNA,
  setIEP,
  setProgressingWell,
  setComments,
  setCourseCategoryComment,
  setMark1,
  setMark2,
  setProgressingWithDifficulty,
  setStudentAssessment,
  setProgressingVeryWell,
  setSubjectId,
  setOrganizationGradeLevelId,
  setCourseId,
  setClassCourseId,
  loadActiveStudents,
  setReportCardDetailDrawerStatus,
  updateStudentAssessmentGrade,
  updateStudentAssessmentComment,
  StudentReportCardDetailDrawerStatus,
  setStudentReportCardEdit,
  setCheckboxValue,
  studentReportCardId,
  setStudentAssessmentEdit, setReportCardData,
  setHoldStudentReportCard,
  setProvisionalReportCardData,
  setProvisionalReportCardCommentData
} = manageReportCardBatchSlice.actions;

export default manageReportCardBatchSlice.reducer;
